.preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  padding: 15px;
  @include response(767px) {
    img {
      max-width: 30%;
    }
  }
  &.hide {
    transform: translateY(-100%);
    opacity: 0;
  }
}