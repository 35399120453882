.line {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #f3f3f4;
  left: 50%;
  @include response(1200px) {
    display: none;
  }
  .header & {
    position: absolute;
  }
  .production & {
    position: absolute;
    background: #dddedf;
    opacity: .3;
  }
  &--1 {
    transform: translateX(-288px);
  }
  &--2 {
    transform: translateX(-576px);
  }
  &--3 {
    transform: translateX(-864px);
  }
  &--4 {
    transform: translateX(288px);
  }
  &--5 {
    transform: translateX(576px);
  }
  &--6 {
    transform: translateX(864px);
  }
}