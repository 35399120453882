.video-play {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 98px;
  background: #fff;
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  @include response(767px) {
    width: 70px;
    height: 70px;
  }
  .partnership__video & {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -49px;
    margin-left: -49px;
    @include response(767px) {
      margin-top: -35px;
      margin-left: -35px;
    }
  }
  @include desktop-hover() {
    svg {
      fill: $red;
    }
    &::before {
      transform: scale(1.1);
    }
  }
  svg {
    fill: $black;
  }
  &::before {
    content: '';
    position: absolute;
    left: -18px;
    right: -18px;
    bottom: -18px;
    top: -18px;
    background: rgba(255,255,255,.3);
    border-radius: 50%;
    transition: transform .3s ease-in-out;
    @include response(767px) {
      left: -14px;
      right: -14px;
      bottom: -14px;
      top: -14px;
    }
  }
}