.text-content {
  position: relative;
  line-height: 26px;
  font-size: 18px;
  @include response(767px) {
    line-height: 23px;
    font-size: 16px;
  }
  p {
    margin: 0 0 30px;
    @include response(767px) {
      margin-bottom: 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: $red;
    text-decoration: none;
    display: inline-block;
    vertical-align: top;
    @include desktop-hover() {
      text-decoration: underline;
    }
  }
  ul {
    list-style: none;
    margin: 0 0 15px;
    padding: 0;
    li {
      position: relative;
      padding-left: 18px;
      font-weight: 500;
      margin-bottom: 10px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: $red;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
h1  {
  font-size: 56px;
  font-weight: 700;
  margin: 0 0 15px;
}
h2  {
  font-size: 38px;
  margin: 0 0 15px;
}
h3  {
  font-size: 28px;
  font-weight: 700;
  margin: 0 0 15px;
}