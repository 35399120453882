.products {
  position: relative;
  &__info {
    display: flex;
    margin-bottom: 175px;
    @include response(1440px) {
      margin-bottom: 70px;
    }
    @include response(1200px) {
      margin-bottom: 40px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__info-text {
    flex: 0 0 50%;
    max-width: 50%;
    padding-top: 55px;
    padding-left: 40px;
    padding-right: 40px;
    @include response(1200px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      order: 2;
    }
    @include response(767px) {
      padding-top: 20px;
    }
  }
  &__text-inner {
    max-height: 505px;
  }
  &__img {
    margin-right: -70px;
    position: relative;
    @include response(1365px) {
      margin-right: 0;
    }
    @include response(1200px) {
      order: 1;
    }
    &::before {
      content: '';
      position: absolute;
      width: 241px;
      height: 308px;
      top: 310px;
      background: #f1f5f5;
      right: 24px;
      z-index: -1;
      @include response(767px) {
        display: none;
      }
    }
  }
}