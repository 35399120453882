.footer {
  flex: 0 0 auto;
  position: relative;
  padding: 40px 37px 40px 127px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include response(1440px) {
    padding: 20px 30px;
  }
  @include response(1200px) {
    flex-wrap: wrap;
    justify-content: center;
    border-top: 1px solid #f3f3f4;
  }
  @include response(767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .body-home & {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 50%;
    @include response(1440px) {
      padding: 40px 37px 40px 100px;
    }
    @include response(1200px) {
      display: none;
    }
  }
  &__contacts {
    display: flex;
    align-items: center;
    @include response(1200px) {
      flex: 0 0 100%;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 25px;
      .body-home & {
        position: absolute;
        top: 0;
        right: 0;
        margin-bottom: 0;
        display: block;
        padding-right: 22px;
        padding-top: 5px;
      }
    }
  }
  &__social {
    display: flex;
    align-items: center;
    margin-right: 56px;
    @include response(1440px) {
      margin-right: 30px;
    }
    @include response(1200px) {
      flex: 0 0 100%;
      margin-right: 0;
      margin-bottom: 10px;
      justify-content: center;
      .body-home & {
        margin-bottom: 2px;
        justify-content: flex-start;
      }
    }
  }
  &__social-link {
    padding: 5px;
    display: flex;
    margin-right: 15px;
    @include response(1200px) {
      margin-right: 10px;
    }
    &:last-child {
      margin-right: 0;
    }
    @include desktop-hover() {
      svg {
        fill: #000;
      }
    }
    svg {
      fill: #b5bebe;
    }
  }
  &__email {
    color: #8a9191;
    font-size: 15px;
    @include desktop-hover() {
      text-decoration: none;
    }
  }
}