.contacts {
  position: relative;
  &__office-box {
    padding-left: 70px;
    padding-right: 70px;
    @include response(1200px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__office-title {
    color: #393c3f;
    font-size: 38px;
    text-transform: uppercase;
    letter-spacing: 8px;
    position: relative;
    padding-bottom: 40px;
    @include response(767px) {
      font-size: 22px;
      padding-bottom: 20px;
    }
    &::before {
      content: '';
      position: absolute;
      left: -70px;
      top: -43px;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      z-index: -1;
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__office-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: 50px;
    @include response(767px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  &__office-item {
    flex: 0 0 calc(50% - 16px);
    max-width: calc(50% - 16px);
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 10px;
    box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background: #fff;
    position: relative;
    @include response(767px) {
      flex: 0 0 calc(100% - 16px);
      max-width: calc(100% - 16px);
    }
    .pharmacovigilance__map-info {
      border: 1px solid #e2e2e2;
      border-right: 0;
      border-top: 0;
      border-radius: 0 0 0 4px;
      @include response(767px) {
        border-right: 1px solid #e2e2e2;
        border-radius: 0;
      }
    }
    &:nth-child(even) {
      .contacts__map-holder {
        left: auto;
        right: 100%;
        border-radius: 4px 0 0 4px;
        border-right: 0;
        border-left: 1px solid #e2e2e2;
        @include response(767px) {
          right: auto;
          border-radius: 0;
          border-right: 1px solid #e2e2e2;
        }
      }
      .pharmacovigilance__map-info {
        border-radius: 0 0 4px 0;
        border-left: 0;
        border-right: 1px solid #e2e2e2;
        @include response(767px) {
          border-radius: 0;
          border-left: 1px solid #e2e2e2;
        }
      }
    }
  }
  &__office-head {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 15px;
    cursor: pointer;
    border-radius: 4px 4px 0 0;
    position: relative;
    @include response(767px) {
      padding: 15px;
    }
    @include desktop-hover() {
      .contacts__office-text {
        color: $red;
        border-color: $red;
        svg {
          fill: $red;
        }
      }
    }
    .contacts__office-item.active & {
      border: 1px solid #e2e2e2;
      border-bottom: 0;
      &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 10px;
        background: #e2e2e2;
        top: 100%;
        left: -1px;
      }
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 10px;
        background: #e2e2e2;
        top: 100%;
        right: -1px;
      }
    }
  }
  &__office-icon {
    margin-right: 14px;
  }
  &__office-text {
    font-size: 17px;
    font-weight: 500;
    color: #50555a;
    display: flex;
    align-items: center;
    border-bottom: 1px dotted #50555a;
    transition: .3s ease-in-out;
    .contacts__office-item.active & {
      color: $red;
      border-color: $red;
    }
    svg {
      margin-left: 10px;
      fill: #50555a;
      .contacts__office-item.active & {
        transform: rotate(180deg);
        fill: $red;
      }
    }
  }
  &__office-holder {
    position: relative;
    margin-top: 10px;
    display: none;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background: #e2e2e2;
      top: -10px;
    }
  }
  &__map-holder {
    position: absolute;
    background: #fff;
    left: 100%;
    top: 0;
    bottom: 0;
    width: calc(100% + 16px);
    padding: 6px;
    border-radius: 0 4px 4px 0;
    border: 1px solid #e2e2e2;
    border-left: 0;
    @include response(767px) {
      position: relative;
      left: auto;
      height: 300px;
      width: 100%;
      border-radius: 0;
      border-left: 1px solid #e2e2e2;
      border-bottom: 0;
      border-top: 0;
    }
  }
  &__office-map {
    height: 100%;
  }
  &__top {
    display: flex;
    margin-left: -215px;
    margin-right: -215px;
    margin-bottom: 150px;
    background: #fff;
    @include response(1440px) {
      margin-left: -100px;
      margin-right: -100px;
    }
    @include response(1439px) {
      margin-left: -50px;
      margin-right: -50px;
      margin-bottom: 70px;
    }
    @include response(1365px) {
      margin-left: -20px;
      margin-right: -20px;
    }
    @include response(1200px) {
      margin-left: -15px;
      margin-right: -15px;
    }
    @include response(1023px) {
      flex-wrap: wrap;
    }
    @include response(767px) {
      margin-bottom: 30px;
    }
  }
  &__cell {
    flex: 0 0 465px;
    max-width: 465px;
    display: flex;
    align-items: center;
    border: 1px solid #e2e2e2;
    background: #f7f7f7;
    position: relative;
    @include response(1440px) {
      flex: 0 0 30%;
    }
    @include response(1439px) {
      flex: 0 0 28%;
    }
    @include response(1023px) {
      order: 2;
      flex: 0 0 50%;
      border-left: 0;
      border-bottom: 0;
    }
    @include response(767px) {
      flex: 0 0 100%;
      border-right: 0;
    } 
    &--left {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 50%;
        left: 0;
        right: 0;
        background: #008bf7;
        opacity: .4;
        @include response(1023px) {
          display: none;
        }
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        top: 50%;
        left: 0;
        right: 0;
        background: #ffdb2d;
        opacity: .4;
        @include response(1023px) {
          display: none;
        }
      }
    }
    &--right {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 50%;
        left: 0;
        right: 0;
        background: #ff9900;
        opacity: .4;
        @include response(1023px) {
          display: none;
        }
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        top: 50%;
        left: 0;
        right: 0;
        background: #47a739;
        opacity: .4;
        @include response(1023px) {
          display: none;
        }
      }
    }
  }
  &__top-map {
    flex: 1 1 auto;
    min-height: 620px;
    @include response(1440px) {
      min-height: 450px;
    }
    @include response(1023px) {
      flex: 0 0 100%;
      min-height: 300px;
    }
  }
  &__cell-box {
    position: relative;
    width: 100%;
    background: #fff;
    z-index: 1;
  }
  &__cell-title {
    display: flex;
    align-items: center;
    padding: 14px 30px;
    border-bottom: 1px solid #f0f0f0;
    @include response(1439px) {
      padding: 14px;
    }
  }
  &__cell-img {
    margin-right: 15px;
  }
  &__cell-titleText {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 900;
    color: $black;
  }
  &__cell-body {
    padding: 30px;
    @include response(1439px) {
      padding: 14px;
    }
  }
  &__cell-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @include response(1439px) {
      margin-bottom: 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__cell-icon {
    flex: 0 0 36px;
    width: 36px;
    margin-right: 13px;
    height: 36px;
    background: #f3f4f5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #aeb1b5;
    }
  }
  &__cell-inner {
    flex: 1 1 auto;
  }
  &__cell-text {
    display: inline-block;
    font-size: 17px;
    font-weight: 500;
    color: $black;
    text-decoration: none;
    line-height: 22px;
    font-style: normal;
    &--tel {
      @include desktop-hover() {
        color: $red;
      }
    }
  }
}
.on-map {
  display: inline-block;
  color: $red;
  font-size: 14px;
  border-bottom: 1px dotted $red;
  cursor: pointer;
  line-height: 1;
  margin-left: 5px;
  transition: .3s ease-in-out;
  @include desktop-hover() {
    border-color: transparent;
  }
}