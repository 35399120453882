.pharmacovigilance {
  position: relative;
  &__img {
    max-width: 1076px;
    width: 100%;
    margin: 0 auto 50px;
    @include response(767px) {
      margin-bottom: 20px;
    }
  }
  &__map-holder {
    max-width: 1090px;
    width: 100%;
    margin: 0 auto 44px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    background: #fff;
    padding: 10px;
    display: flex;
    @include response(767px) {
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      box-shadow: none;
      border-radius: 0;
      width: auto;
    }
  }
  &__map {
    flex: 0 0 543px;
    max-width: 543px;
    @include response(1200px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include response(767px) {
      flex: 0 0 100%;
      max-width: 100%;
      height: 300px;
    }
  }
  &__map-info {
    padding: 66px 50px 60px;
    @include response(1200px) {
      padding: 20px;
    }
    @include response(767px) {
      padding: 15px;
    }
    .contacts__office-holder & {
      padding: 40px;
      @include response(1200px) {
        padding: 15px;
      }
    }
  }
  &__map-title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 16px;
    color: $black;
  }
  &__row {
    position: relative;
    display: flex;
    margin-bottom: 15px;
    .menu__contacts-cell & {
      margin-bottom: 17px;
    }
  }
  &__row-name {
    color: #8e9399;
    font-size: 15px;
    margin-right: 10px;
    flex: 0 0 auto;
  }
  &__row-box {
    flex: 1 1 auto;
  }
  &__row-value {
    font-size: 16px;
    color: #50555a;
    font-weight: 500;
    text-decoration: none;
    font-style: normal;
    &--tel {
      @include desktop-hover() {
        color: $red;
      }
    }
  }
  &__btn-box {
    margin-top: 30px;
  }
  &__btn {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    font-size: 16px;
    color: #50555a;
    svg {
      margin-left: 8px;
    }
    @include desktop-hover() {
      color: $red;
      svg {
        fill: $red;
        transform: translateX(5px);
      }
    }
  }
  &__map-descriptions {
    text-align: center;
    font-weight: 300;
    font-size: 18px;
    font-style: italic;
    color: #50555a;
    margin-bottom: 90px;
    @include response(767px) {
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 16px;
    }
  }
}