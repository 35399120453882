.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 37px 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  background: #fff;
  @include response(1439px) {
    padding: 15px;
  }
  @include response(1200px) {
    padding: 0;
    border-bottom: 1px solid #eaeeee;
  }
  .wrapper--home & {
    padding-right: 120px;
    right: 50%;
    background: transparent;
    @include response(1850px) {
      padding-right: 15px;
    }
    @include response(1200px) {
      right: 0;
      padding-right: 0;
      background: #fff;
    }
    .line {
      display: none;
    }
  }
  &__left {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    @include response(1200px) {
      position: static;
    }
  }
  &__logo {
    background: url('../images/logo.png') center/ 100% 100% no-repeat;
    width: 48px;
    height: 66px;
    margin-right: 50px;
    @include response(1630px) {
      margin-right: 20px;
    }
    @include response(1439px) {
      width: 39px;
      height: 53px;
    }
    @include response(1200px) {
      position: absolute;
      left: 50%;
      margin-left: -17px;
      top: 50%;
      margin-top: -24px;
      width: 35px;
      height: 48px;
      .menu & {
        display: none;
      }
    }
  }
  &__logo-link {
    display: block;
    height: 100%;
  }
  &__menu-btn {
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 34px;
    @include response(1200px) {
      margin-right: 0;
      height: 60px;
      padding-left: 18px;
      padding-right: 18px;
      position: relative;
      justify-content: center;
      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 9px;
        bottom: 7px;
        width: 1px;
        background: #eaeeee;
      }
    }
    @include desktop-hover() {
      .header__line {
        width: 18px;
      }
    }
  }
  &__menu-btnText {
    display: none;
    @include response(1200px) {
      display: block;
      color: $black;
      font-size: 10px;
    }
  }
  &__menu-btnBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include response(1200px) {
      margin-bottom: 8px;
    }
  }
  &__line {
    height: 3px;
    background: $black;
    margin-bottom: 3px;
    transition: .3s ease-in-out;
    &:last-child {
      margin-bottom: 0;
    }
    &--1 {
      width: 12px;
    }
    &--2 {
      width: 15px;
    }
    &--3 {
      width: 18px;
    }
  }
}

.nav {
  display: flex;
  @include response(1200px) {
    display: none;
  }
  &__item {
    position: relative;
    margin-right: 50px;
    @include response(1630px) {
      margin-right: 20px;
    }
    @include desktop-hover() {
      .drop {
        left: 50%;
        opacity: 1;
        transform: translate(0,0);
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__link-parent {
    color: #333;
    font-weight: 500;
    font-size: 17px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 9px;
    padding-left: 3px;
    padding-right: 3px;
    border-bottom: 1px solid #c3c4c5;
    @include response(1439px) {
      .wrapper--home & {
        font-size: 12px;
      }
    }
    @include desktop-hover() {
      border-color: $red;
      svg {
        fill: $red;
        transform: rotate(180deg);
      }
    }
    svg {
      margin-left: 5px;
      fill: #333;
    }
  }
}
.drop {
  list-style: none;
  margin: 0;
  padding: 11px 0;
  position: absolute;
  top: 100%;
  width: 200px;
  left: -100vw;
  opacity: 0;
  margin-left: -100px;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #fff;
  z-index: 1;
  transform: translate(0,20px);
  transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  &::before {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 2px;
  }
  &__item {
    position: relative;
    @include desktop-hover() {
      .drop__drop {
        left: 100%;
        transform: translate(0,0);
        opacity: 1;
      }
    }
  }
  &__link {
    color: $black;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    padding: 11px 26px 11px 40px;
    display: block;
    @include desktop-hover() {
      color: $red;
      svg {
        fill: $red;
      }
    }
    svg {
      position: absolute;
      right: 12px;
      top: 50%;
      margin-top: -3px;
    }
  }
  &__drop {
    position: absolute;
    left: -100vw;
    top: -11px;
    width: 200px;
    opacity: 0;
    transform: translate(20px,0);
    list-style: none;
    padding: 11px 0;
    margin: 0;
    box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: #fff;
    z-index: 1;
    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  }
  &__drop-link {
    color: $black;
    font-size: 16px;
    font-weight: 500;
    display: block;
    text-decoration: none;
    padding: 11px 26px 11px 40px;
    @include desktop-hover() {
      color: $red;
      text-decoration: underline;
    }
  }
}

.worldwide-btn {
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgba(233,14,139,.3);
  width: 136px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 14px;
  background: #fff;
  transition: .3s ease-in-out;
  @include response(1200px) {
    height: auto;
    border: 0;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 10px;
    width: 75px;
    padding-top: 12px;
    padding-bottom: 13px;
    .menu__head &:not(.js-worldwide-close) {
      display: none;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 9px;
      bottom: 7px;
      width: 1px;
      background: #eaeeee;
    }
  }
  @include desktop-hover() {
    background: $red;
    border-color: $red;
    color: #fff;
    svg {
      fill: #fff;
    }
  }
  svg {
    fill: $red;
    margin-right: 8px;
    @include response(1200px) {
      margin-right: 0;
      margin-bottom: 5px;
      .menu__head & {
        margin-right: 8px;
        margin-bottom: 0;
      }
    }
  }
  &__text {
    @include response(1200px) {
      flex: 0 0 100%;
      text-align: center;
    }
  }
}