@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .menu__holder {
    flex: 1 1 auto;
    .menu--worldwide & {
      max-width: 576px;
      width: 100%;
    }
  }
  .main-slider__for-item {
    height: calc(100vh - 195px);
  }
  .error-page {
    height: calc(100vh - 108px);
  }
}
@-moz-document url-prefix() {

}
