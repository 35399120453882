.career {
  position: relative;
  &__top {
    position: relative;
    margin-bottom: 80px;
    @include response(1200px) {
      margin-bottom: 40px;
    }
  }
  &__top-head {
    display: flex;
    align-items: flex-start;
    @include response(767px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__top-text {
    padding: 38px 80px 38px 90px;
    @include response(1200px) {
      padding: 0 20px 0 0;
    }
    @include response(767px) {
      order: 2;
      padding-right: 0;
    }
  }
  &__top-img {
    flex: 0 0 auto;
    position: relative;
    @include response(767px) {
      margin-bottom: 15px;
    }
    img {
      position: relative;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -120px;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__top-bottom {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -70px;
    @include response(1200px) {
      margin-top: 20px;
    }
  }
  &__img-decor {
    margin-right: 150px;
    @include response(1200px) {
      margin-right: 20px;
      flex: 0 0 40%;
    }
    @include response(767px) {
      display: none;
    }
  }
  &__decor-text {
    position: relative;
    padding-bottom: 42px;
    @include response(1200px) {
      padding-bottom: 0;
    }
    @include response(767px) {
      display: flex;
    }
    &::before {
      content: '';
      position: absolute;
      left: -50px;
      top: -10px;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      transform: scaleX(-1);
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__decor-top {
    position: relative;
    display: flex;
    align-items: flex-end;
    color: $red;
    font-weight: 700;
    @include response(767px) {
      align-items: center;
    }
  }
  &__decor-topText {
    font-size: 28px;
    @include response(767px) {
      font-size: 14px;
      margin-right: 10px;
    }
  }
  &__decor-topValue {
    font-size: 80px;
    line-height: 63px;
    @include response(767px) {
      font-size: 30px;
      line-height: 1;
    }
  }
  &__decor-bottom {
    position: relative;
    font-size: 60px;
    color: $black;
    font-weight: 700;
    padding-left: 130px;
    @include response(767px) {
      padding-left: 10px;
      font-size: 26px;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    padding-left: 74px;
    padding-right: 105px;
    padding-bottom: 50px;
    @include response(1200px) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 30px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__info-text {
    flex: 1 1 auto;
    @include response(1200px) {
      order: 2;
    }
  }
  &__info-title {
    color: $black;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
    @include response(767px) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
  &__info-descriptions {
     color: #50555a;
   }
  &__info-img {
    margin-left: 95px;
    position: relative;
    flex: 0 0 auto;
    @include response(1200px) {
      margin-left: 0;
      margin-bottom: 20px;
    }
    @include response(767px) {
      flex: 0 0 100%;
    }
    img {
      position: relative;
    }
    &::before {
      content: '';
      position: absolute;
      background: #f1f5f5;
      width: 198px;
      height: 254px;
      bottom: -30px;
      right: -25px;
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__healthcare {
    position: relative;
    padding-top: 90px;
    margin-bottom: 90px;
    @include response(1200px) {
      padding-top: 40px;
      margin-bottom: 40px;
    }
    @include response(767px) {
      padding-top: 20px;
    }
  }
  &__healthcare-title {
    text-align: center;
    margin-bottom: 25px;
    color: $black;
    font-size: 28px;
    font-weight: 700;
    @include response(767px) {
      font-size: 22px;
      margin-bottom: 15px;
    }
  }
  &__healthcare-text {
    display: flex;
    align-items: flex-start;
    @include response(767px) {
      flex-wrap: wrap;
    }
  }
  &__healthcare-cell {
    margin-left: 70px;
    max-width: 490px;
    padding-bottom: 60px;
    position: relative;
    @include response(1200px) {
      margin-left: 0;
      padding-bottom: 40px;
      & + .career__healthcare-cell {
        margin-left: 30px;
      }
    }
    @include response(767px) {
      max-width: 100%;
      width: 100%;
      margin-bottom: 15px;
      padding-bottom: 0;
      &:last-child {
        margin-bottom: 0;
      }
      & + .career__healthcare-cell {
        margin-left: 0;
      }
    }
    p {
      margin-bottom: 40px;
    }
  }
  &__quote {
    position: absolute;
    bottom: 10px;
    left: 0;
    color: #72777b;
    font-weight: 300;
    font-size: 14px;
    padding-left: 40px;
    line-height: 1;
    @include response(767px) {
      position: relative;
      bottom: auto;
    } 
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -8px;
      width: 24px;
      height: 21px;
      background: url('../images/quote.png') center/100% 100% no-repeat;
    }
  }
}