.production {
  position: relative;
  &__top {
    margin-bottom: 70px;
    margin-left: -214px;
    margin-right: -214px;
    padding: 145px 15px 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @include response(1600px) {
      margin-left: -120px;
      margin-right: -120px;
    }
    @include response(1439px) {
      margin-left: -80px;
      margin-right: -80px;
      padding-top: 70px;
      padding-bottom: 100px;
      margin-bottom: 30px;
    }
    @include response(1365px) {
      margin-left: -20px;
      margin-right: -20px;
    }
    @include response(1200px) {
      margin-left: -15px;
      margin-right: -15px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @include response(767px) {
      margin-bottom: 10px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0,0,0,.3);
    }
  }
  &__top-title {
    position: relative;
    text-align: center;
    font-size: 26px;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 4px;
    margin-bottom: 10px;
    font-weight: 300;
    @include response(767px) {
      font-size: 20px;
    }
  }
  &__top-text {
    position: relative;
    text-align: center;
    font-size: 48px;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 5px;
    @include response(767px) {
      font-size: 28px;
    }
  }
  &__text {
    max-width: 810px;
    width: 100%;
    margin: 0 auto 170px;
    @include response(1440px) {
      margin-bottom: 70px;
    }
    @include response(767px) {
      margin-bottom: 30px;
    }
  }
  &__slider {
    @include response(1200px) {
      margin-right: -15px;
      margin-left: -15px;
    }
    .slick-slide {
      margin-left: 13px;
      margin-right: 13px;
      opacity: .2;
      transition: opacity .3s ease-in-out;
      &.slick-current {
        opacity: 1;
      }
    }
  }
  &__slider-controls {
    .hide-arrows & {
      display: none;
    }
  }
  &__slider-controlsPrev {
    display: block !important;
    position: absolute;
    top: calc(50% - 135px);
    outline: none;
    left: 0;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    @include response(1200px) {
      top: 0;
      bottom: 0;
      width: 30px;
      z-index: 1;
    }
    .slick-current & {
      opacity: 1;
    }
    .slick-prev {
      border-radius: 0 100% 100% 0 / 0 50% 50% 0;
      width: 50px;
      height: 100px;
      background: rgba(0,0,0,.6);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
      transition: .3s ease-in-out;
      @include response(1200px) {
        height: 100%;
        width: 100%;
        border-radius: 0;
      }
      @include desktop-hover() {
        background: rgba(0,0,0,.8);
      }
      svg {
        fill: #fff;
      }
    }
  }
  &__slider-controlsNext {
    display: block !important;
    position: absolute;
    top: calc(50% - 135px);
    outline: none;
    transition: opacity .3s ease-in-out;
    right: 0;
    opacity: 0;
    @include response(1200px) {
      top: 0;
      bottom: 0;
      width: 30px;
      z-index: 1;
    }
    .slick-current & {
      opacity: 1;
    }
    .slick-next {
      border-radius: 100% 0 0 100% / 50% 0 0 50%;
      width: 50px;
      height: 100px;
      background: rgba(0,0,0,.6);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
      transition: .3s ease-in-out;
      @include response(1200px) {
        height: 100%;
        width: 100%;
        border-radius: 0;
      }
      @include desktop-hover() {
        background: rgba(0,0,0,.8);
      }
      svg {
        fill: #fff;
      }
    }
  }
  &__slider-item {
    padding-bottom: 135px;
    position: relative;
    @include response(1440px) {
      padding-bottom: 80px;
    }
    @include response(1200px) {
      padding-bottom: 0;
    }
  }
  &__slider-info {
    position: absolute;
    left: 40px;
    bottom: 50px;
    background: #fff;
    box-shadow: 0 30px 32px 0 rgba(0, 0, 0, 0.05);
    max-width: 540px;
    padding: 38px 43px;
    transition: .3s ease-in-out;
    z-index: 1;
    opacity: 0;
    transform: translateY(15px);
    transition-delay: .3s;
    @include response(1440px) {
      bottom: 60px;
      padding: 25px 20px;
    }
    @include response(1200px) {
      position: relative;
      bottom: auto;
      left: auto;
      box-shadow: none;
      max-width: 100%;
      z-index: -1;
      padding: 25px 60px;
    }
    @include response(767px) {
      padding: 15px 38px;
    }
    .slick-current & {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &__slider-title {
    font-size: 24px;
    font-weight: 500;
    color: $black;
    margin-bottom: 10px;
    @include response(767px) {
      font-size: 18px;
    }
  }
  &__slider-text {
    font-size: 18px;
    line-height: 28px;
    @include response(767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}