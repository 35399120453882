.marketing {
  position: relative;
  padding-bottom: 130px;
  @include response(1440px) {
    padding-bottom: 40px;
  }
  &__top {
    display: flex;
    margin-bottom: 80px;
    position: relative;
    @include response(1440px) {
      margin-bottom: 40px;
    }
    @include response(767px) {
      margin-bottom: 20px;
      flex-wrap: wrap;
    }
    &--products {
      margin-bottom: 30px;
      @include response(767px) {
        margin-bottom: 20px;
      }
    }
  }
  &__top-title {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 125px;
    position: relative;
    color: #393c3f;
    font-size: 38px;
    text-transform: uppercase;
    letter-spacing: 7px;
    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;
    @include response(1440px) {
      padding-bottom: 40px;
    }
    @include response(767px) {
      padding-left: 0;
      padding-right: 0;
      flex: 0 0 100%;
      max-width: 100%;
      font-size: 24px;
      padding-bottom: 10px;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 40px;
      right: 40px;
      height: 1px;
      background: #ddd;
      @include response(767px) {
        left: 0;
        right: 0;
      }
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      z-index: -1;
      @include response(767px) {
        display: none;
      }
    }
  }
  &__top-text {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    color: #3d4144;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    font-style: italic;
    padding: 0 70px 10px 80px;
    @include response(1440px) {
      padding-left: 30px;
      padding-right: 15px;
    }
    @include response(767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      padding-top: 10px;
      font-size: 18px;
      line-height: 22px;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      z-index: -1;
      @include response(767px) {
        display: none;
      }
    }
    &--products {
      &::after {
        transform: scaleX(-1);
      }
    }
  }
  &__img-box {
    margin-bottom: 50px;
    @include response(767px) {
      margin-bottom: 10px;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 34px;
    @include response(767px) {
      flex-wrap: wrap;
      margin-bottom: 10px;
    }
  }
  &__cell-right {
    margin-left: 30px;
    flex: 0 0 auto;
    @include response(1200px) {
      margin-left: 15px;
      flex: 0 0 46%;
    }
    @include response(767px) {
      margin-left: 0;
      margin-bottom: 10px;
      flex: 0 0 100%;
    }
  }
  &__cell-left {
    position: relative;
    @include response(1200px) {
      flex: 0 0 46%;
    }
    @include response(767px) {
      flex: 0 0 100%;
      margin-bottom: 10px;
    }
    &--text {
      padding: 45px 18px 45px 20px;
      @include response(1200px) {
        padding: 0;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 15px;
        width: 24px;
        height: 21px;
        background: url('../images/quote.png') center/100% 100% no-repeat;
        @include response(1200px) {
          display: none;
        }
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: -20px;
        width: 209px;
        height: 202px;
        background: url('../images/decor4.png') center/100% 100% no-repeat;
        z-index: -1;
        @include response(767px) {
          display: none;
        }
      }
    }
  }
  &__cell-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 28px;
    font-style: italic;
    @include response(767px) {
      margin-bottom: 10px;
    }
  }
  &__cell-by {
    color: #72777b;
    font-size: 14px;
    font-weight: 300;
  }
  &__info {
    position: relative;
    padding-top: 40px;
    @include response(767px) {
      padding-top: 10px;
    }
    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      z-index: -1;
      @include response(1200px) {
        display: none;
      }
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 280px;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      z-index: -1;
      transform: scaleX(-1);
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__info-title {
    font-size: 28px;
    font-weight: 700;
    color: $black;
    margin-bottom: 20px;
    text-align: center;
    @include response(767px) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
  &__info-text {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    color: $black;
  }
}