.infowindow {
  position: relative;
  max-width: 231px;
  width: 100%;
  @include response(767px) {
    max-width: 200px;
  } 
  &__img {
    display: block;
  }
  &__text {
    padding-top: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $black;
    @include response(767px) {
      font-size: 10px;
      line-height: 12px;
    }
  }
}
.gm-style img {
  max-width: 100% !important;
}