.citation {
  position: relative;
  padding: 47px 0 68px;
  margin-top: 80px;
  margin-bottom: 80px;
  @include response(1440px) {
    margin-top: 60px;
    margin-bottom: 60px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @include response(1200px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &--people-one {
    margin-bottom: 100px;
    @include response(1440px) {
      margin-bottom: 60px;
    }
    @include response(1200px) {
      margin-bottom: 30px;
    }
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 290px;
    height: 203px;
    background: url('../images/decor.png') center/100% 100% no-repeat;
    z-index: -1;
    @include response(767px) {
      display: none;
    }
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 290px;
    height: 203px;
    background: url('../images/decor.png') center/100% 100% no-repeat;
    transform: scaleX(-1);
    z-index: -1;
    @include response(767px) {
      display: none;
    }
  }
  &--pharmacovigilance {
    margin-top: 50px;
    margin-bottom: 0;
    padding-bottom: 30px;
    @include response(767px) {
      margin-top: 20px;
    }
    &::after {
      display: none;
    }
  }
  &__text {
    max-width: 664px;
    width: 100%;
    margin: 0 auto 15px;
    color: $black;
    font-weight: 500;
    font-size: 20px;
    font-style: italic;
    text-align: center;
    line-height: 26px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: -15px;
      top: -24px;
      width: 24px;
      height: 21px;
      background: url('../images/quote.png') center/100% 100% no-repeat;
    }
    &--pharmacovigilance {
      max-width: 760px;
      width: 100%;
      margin-bottom: 0;
      font-size: 18px;
      &::before {
        display: none;
      }
    }
  }
  &__name {
    text-align: center;
    font-size: 14px;
    color: #666;
    font-weight: 300;
  }
}