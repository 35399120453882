.research-page {
  position: relative;
  &__box {
    display: flex;
    margin-bottom: 136px;
    @include response(1200px) {
      flex-wrap: wrap;
      margin-bottom: 40px;
      justify-content: center;
    }
  }
  &__img {
    flex: 0 0 auto;
    margin-right: 54px;
    @include response(1200px) {
      margin-right: 0;
      margin-bottom: 15px;
      flex: 0 0 80%;
      img {
        width: 100%;
      }
    }
    @include response(767px) {
      flex: 0 0 100%;
    }
  }
  &__info {
    padding-right: 45px;
    position: relative;
    flex: 1 1 auto;
    padding-top: 20px;
    @include response(1200px) {
      padding-right: 0;
    }
    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: -70px;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      z-index: -1;
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__title {
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 38px;
    letter-spacing: 5px;
    @include response(767px) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
  &__text {
    max-height: 420px;
    @include response(1200px) {
      max-height: 220px;
    }
  }
}