.js-text {
  &__inner {
    overflow: hidden;
    margin-bottom: 18px;
    &.height-auto {
      height: auto;
    }
  }
  &__open-btn {
    color: #333;
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-right: 20px;
    cursor: pointer;
    text-decoration: none;
    @include desktop-hover() {
      color: $red;
      &::before {
        border-color: $red;
      }
    }
    &::before {
      content: '';
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -3px;
      width: 4px;
      height: 4px;
      border-left: 1px solid #333;
      border-bottom: 1px solid #333;
      transform: rotate(-45deg);
    }
    &.open {
      &::before {
        transform: rotate(135deg);
      }
    }
  }
}