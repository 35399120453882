.history {
  position: relative;
  @include response(1200px) {
    padding-bottom: 15px;
  }
  &__decor-box {
    position: relative;
  }
  &__decor {
    color: #b5bebe;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 300;
    position: relative;
    padding-left: 30px;
    transform: rotate(-90deg);
    position: absolute;
    left: -5px;
    top: 250px;
    transform-origin: 0 0;
    @include response(1200px) {
      display: none;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 7px;
      height: 7px;
      background: #b5bebe;
      border-radius: 50%;
    }
  }
  &__nav {
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 55px;
    @include response(1200px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @include response(767px) {
      margin-bottom: 20px;
    }
    .slick-active {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        background: #fff;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        left: 50%;
        top: -13px;
        margin-left: -13px;
        z-index: 1;
      }
      & + .slick-active {
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          right: 50%;
          top: 5px;
          height: 1px;
          background: #ddd;
        }
      }
    }
    .slick-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      z-index: 1;
      cursor: pointer;
      position: absolute;
      height: 100%;
      top: -16px;
      @include desktop-hover() {
        svg {
          fill: $red;
        }
      }
      &.slick-prev {
        left: 23px;
        @include response(1200px) {
          left: 0;
        }
      }
      &.slick-next {
        right: 23px;
        @include response(1200px) {
          right: 0;
        }
      }
    }
  }
  &__nav-item {
    text-align: center;
    color: #b5bebe;
    font-size: 16px;
    position: relative;
    padding-top: 25px;
    cursor: pointer;
    z-index: 2;
    transition: .3s ease-in-out;
    .slick-current & {
      color: $red;
      font-weight: 700;
      &::before {
        background: #ffcde8;
      }
      &::after {
        background: $red;
      }
    }
    @include desktop-hover() {
      color: $red;
      font-weight: 700;
      &::before {
        background: #ffcde8;
      }
      &::after {
        background: $red;
      }
    }
    &::before {
      content: '';
      position: absolute;
      background: #f0f2f2;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      top: 0;
      left: 50%;
      margin-left: -6px;
      transition: .3s ease-in-out;
    }
    &::after {
      content: '';
      position: absolute;
      background: #b5bebe;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      top: 4px;
      left: 50%;
      margin-left: -2px;
      transition: .3s ease-in-out;
    }
  }
  &__for-item {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    @include response(1023px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__for-info {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 68px;
    padding-right: 30px;
    @include response(1200px) {
      padding-left: 0;
      padding-right: 20px;
    }
    @include response(1023px) {
      flex: 0 0 100%;
      max-width: 100%;
      order: 2;
      padding-right: 0;
    }
  }
  &__year {
    color: $red;
    font-size: 56px;
    margin-bottom: 15px;
    @include response(767px) {
      font-size: 32px;
      margin-bottom: 10px;
    }
  }
  &__title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  &__for-content {
    height: 285px;
  }
  &__img-sliderItem {
    vertical-align: top;
  }
  &__img-slider {
    flex: 0 0 545px;
    max-width: 545px;
    @include response(1200px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include response(1023px) {
      margin-bottom: 20px;
    }
    @include response(767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .slick-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 50px;
      height: 50px;
      background: #fff;
      z-index: 1;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      &.slick-prev {
        right: 50px;
        &::before {
          content: '';
          position: absolute;
          right: 0;
          width: 1px;
          height: 26px;
          background: #d7d8d9;
        }
      }
      &.slick-next {
        right: 0;
      }
    }
  }
}