.dev {
  text-decoration: none;
  display: flex;
  flex: 0 0 auto;
  margin-right: 95px;
  @include response(1440px) {
    margin-right: -20px;
  }
  @include response(1200px) {
    margin-right: 0;
    flex: 0 0 100%;
    margin-bottom: 10px;
    justify-content: center;
  }
  .body-home .footer & {
    display: none;
  }
  > span {
    flex: 0 0 auto;
    margin-right: 10px;
    color: #8e9399;
    font-size: 14px;
    font-weight: 300;
    @include response(1200px) {
      font-size: 12px;
    }
  }
  @include desktop-hover() {
    .lemon-icon {
      transform: translate(0,-100%);
    }
    .lemon-icon-hover {
      transform: translate(0,-100%);
    }
  }
}
.lemon {
  width: 80px;
  height: 13px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  @include response(767px) {
    width: 89px;
    height: 13px;
  }
  svg {
    width: 100%;
    height: 100%;
    transition: .3s ease-in-out;
    fill: #9ba6ad;
  }
}
