.main-slider-box {
  position: relative;
}
.main-slider {
  @include desktop-hover() {
    .slick-active .circle {
      animation: none;
    }
  } 
  &__item {
    display: flex !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    @include response(1200px) {
      min-height: calc(100vh - 61px);
      flex-direction: column;
    }
  }
  &__item-info {
    flex: 0 0 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include response(1200px) {
      flex: 0 0 auto;
      order: 2;
      min-height: calc(45vh - 30px);
      width: auto;
    }
    @include response(767px) {
      padding-top: 30px;
      padding-bottom: 30px;
      justify-content: flex-start;
    }
  }
  &__item-photos {
    flex: 0 0 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    @include response(1200px) {
      flex: 0 0 auto;
      width: auto;
    }
  }
  &__for-item {
    min-height: calc(100vh - 195px);
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include response(1440px) {
      min-height: calc(100vh - 150px);
    }
    @include response(1200px) {
      min-height: calc(55vh - 31px);
    }
  }
  &__nav {
    position: absolute !important;
    right: 0;
    bottom: 0;
    width: 50%;
    @include response(1200px) {
      display: none !important;
    }
    .slick-list {
      padding-right: 97px;
      @include response(1439px) {
        padding-right: 0;
      }
    }
  }
  &__nav-item {
    display: block !important;
    height: 195px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    cursor: pointer;
    transition: .3s ease-in-out;
    @include response(1365px) {
      height: 150px;
    }
    @include desktop-hover() {
      &::before {
        opacity: 0;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: .3s ease-in-out;
      background: rgba(0,0,0,.6);
      z-index: 1;
      .slick-current & {
        opacity: 0;
      }
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 4px;
      background: transparent;
      transition: .3s ease-in-out;
      .slick-current & {
        background: $red;
      }
    }
  }
  &__nav-counter {
    position: absolute;
    top: 12px;
    left: 20px;
    font-size: 20px;
    color: #fff;
  }
  &__nav-box {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    &::before {
      content: '';
      position: absolute;
      bottom: -20px;
      left: -20px;
      right: -20px;
      background: linear-gradient( 0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
      height: 128px;
    }
  }
  &__nav-label {
    font-size: 12px;
    color: #fff;
    margin-bottom: 8px;
    position: relative;
  }
  &__nav-title {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    @include response(1365px) {
      font-size: 14px;
    }
  }
  &__item-label {
    max-width: 560px;
    width: 100%;
    margin: 0 auto 20px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    color: $red;
    transition: .6s cubic-bezier(0.39, 0.575, 0.565, 1);
    @include response-min(1201px) {
      opacity: 0;
      transform: translateY(40px);
      &.animations {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @include response(1440px) {
      margin-bottom: 10px;
    }
    @include response(1365px) {
      padding-left: 20px;
      padding-right: 15px;
    }
    @include response(767px) {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
  &__item-title {
    text-transform: uppercase;
    max-width: 560px;
    width: 100%;
    margin: 0 auto 5px;
    font-size: 56px;
    font-weight: 700;
    color: $black;
    transition: .6s cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-delay: .2s;
    @include response-min(1201px) {
      opacity: 0;
      transform: translateY(40px);
      &.animations {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @include response(1440px) {
      font-size: 44px;
    }
    @include response(1365px) {
      padding-left: 20px;
      padding-right: 15px;
    }
    @include response(767px) {
      font-size: 26px;
    }
  }
  &__item-descriptions {
    max-width: 560px;
    width: 100%;
    margin: 0 auto 30px;
    font-size: 32px;
    font-style: italic;
    color: $black;
    font-weight: 300;
    transition: .6s cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-delay: .6s;
    @include response-min(1201px) {
      opacity: 0;
      transform: translateY(40px);
      &.animations {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @include response(1440px) {
      font-size: 28px;
      margin-bottom: 20px;
    }
    @include response(1365px) {
      padding-left: 20px;
      padding-right: 15px;
    }
    @include response(767px) {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
  &__item-text {
    max-width: 560px;
    width: 100%;
    margin: 0 auto 40px;
    transition: .6s cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-delay: 1s;
    @include response-min(1201px) {
      opacity: 0;
      transform: translateY(40px);
      &.animations {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @include response(1440px) {
      margin-bottom: 20px;
    }
    @include response(1365px) {
      padding-left: 20px;
      padding-right: 15px;
    }
    @include response(1200px) {
      display: none;
    }
  }
  &__item-btn {
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
    transition: .6s cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-delay: 1.4s;
    @include response-min(1201px) {
      opacity: 0;
      transform: translateY(40px);
      &.animations {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @include response(1365px) {
      padding-left: 20px;
      padding-right: 15px;
    }
  }
  &__controls {
    position: absolute;
    left: 23px;
    bottom: 43px;
    display: flex;
    font-size: 14px;
    color: #b5bebe;
    @include response(1200px) {
      display: none;
    }
  }
  &__current {
    font-size: 22px;
    font-weight: 900;
    color: $red;
  }
  &__all {
    position: relative;
  }
}
.main-slider-controls {
  display: flex;
  position: absolute;
  bottom: 30px;
  left: 40%;
  z-index: 1;
  @include response(1200px) {
    display: none;
  }
  .slick-arrow {
    padding: 13px 16px;
    display: flex;
    cursor: pointer;
    @include desktop-hover() {
      svg {
        fill: $red;
      }
    }
    svg {
      fill: #000;
    }
  }
  .slick-prev {
    border-right: 1px solid #d7d8d9;
  }
}
.circle {
  transform: rotate(-90deg);
  transform-origin: center;
  opacity: 0;
  .slick-active & {
    animation: circle 10s linear forwards;
  }
}