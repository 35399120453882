.counter {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  @include response(1200px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @include response(767px) {
    flex-wrap: wrap;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: -25px;
    width: 106px;
    height: 121px;
    background: url('../images/decor2.png') center/100% 100% no-repeat;
    @include response(1200px) {
      display: none;
    }
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: -25px;
    width: 106px;
    height: 121px;
    background: url('../images/decor3.png') center/100% 100% no-repeat;
    @include response(1200px) {
      display: none;
    }
  }
  &--our-strategy {
    margin-bottom: 50px;
    @include response(1200px) {
      margin-bottom: 30px;
    }
    @include response(767px) {
      margin-bottom: 20px;
    }
    &::before {
      display: none;
    }
    &::after {
      top: -5px;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      z-index: -1;
      transform: scaleX(-1);
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__item {
    position: relative;
    width: 100%;
    padding-left: 47px;
    padding-right: 47px;
    text-align: center;
    @include response(1200px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @include response(767px) {
      width: 50%;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  &__value {
    color: $red;
    font-size: 80px;
    font-weight: 700;
    margin-bottom: 10px;
    @include response(1200px) {
      font-size: 60px;
    }
    @include response(767px) {
      font-size: 40px;
    }
  }
  &__item-title {
    text-transform: uppercase;
    color: $black;
    font-weight: 700;
    font-size: 18px;
    height: 36px;
    overflow: hidden;
    @include response(767px) {
      font-size: 16px;
      height: 32px;
    }
  }
  &__item-descriptions {
    line-height: 23px;
    font-size: 18px;
    @include response(1200px) {
      font-size: 16px;
    }
    @include response(767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}