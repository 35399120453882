.purpose {
  position: relative;
  max-width: 1590px;
  width: 100%;
  margin: 0 auto;
  padding-top: 110px;
  padding-bottom: 110px;
  @include response(1439px) {
    padding-bottom: 40px;
    padding-top: 90px;
  }
  &__box {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    top: -40px;
    padding-left: 190px;
    padding-top: 35px;
    @include response(1440px) {
      top: auto;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 40px;
    }
    @include response(767px) {
      padding: 0;
    }
  }
  &__holder {
    background: #fff;
    max-width: 562px;
    width: 100%;
    position: relative;
    top: 80px;
    box-shadow: 0 41px 70px 0 rgba(0, 0, 0, 0.08);
    padding: 66px 60px 60px;
    @include response(1440px) {
      top: 0;
    }
    @include response(1200px) {
      max-width: 100%;
    }
    @include response(767px) {
      padding: 0 15px;
      box-shadow: none;
    }
  }
  &__title {
    color: $black;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  &__position {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
    color: $red;
  }
  &__holder-bottom {
    position: relative;
    margin-top: 36px;
    padding-top: 42px;
    border-top: 1px dotted #ddd;
  }
  &__quote {
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 15px;
    line-height: 26px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: -10px;
      top: -15px;
      width: 24px;
      height: 21px;
      background: url('../images/quote.png') center/100% 100% no-repeat;
    }
    // &::after {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   top: -px;
    //   width: 106px;
    //   height: 121px;
    //   background: url('../images/decor2.png') center/100% 100% no-repeat;
    // }
  }
  &__by {
    color: #666;
    font-size: 14px;
    font-weight: 300;
  }
}