.our-strategy {
  position: relative;
  &__img {
    position: relative;
    max-width: 1580px;
    width: 100%;
    margin: 0 auto 90px;
    @include response(1600px) {
      max-width: 94vw;
    }
    @include response(1200px) {
      margin-bottom: 30px;
    }
  }
  &__text-holder {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -95px;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      z-index: -1;
      transform: scaleX(-1);
      @include response(1200px) {
        display: none;
      }
    }
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -95px;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      z-index: -1;
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__text {
    max-width: 810px;
    width: 100%;
    margin: 0 auto 100px;
    position: relative;
    @include response(1200px) {
      margin-bottom: 40px;
    }
  }
}