/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    .history & {
      margin-left: 0;
      margin-right: 0;
    }
    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    outline: none;
    min-height: 1px;
    .products-slider & {
      margin-left: 5px;
      margin-right: 5px;
      @include response(767px) {
        // text-align: center;
        margin-left: 0;
        margin-right: 0;
      }
    }
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-dots {
  padding: 0;
  list-style: none;
  margin: 0;
  position: absolute;
  .main-slider & {
    left: 36px;
    top: 50%;
    transform: translate(0,-50%);
    @include response(1366px) {
      left: 16px;
    }
    @include response(1200px) {
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      bottom: 10px;
      top: auto;
      transform: none;
    }
    }
    li {
      display: flex;
      margin-bottom: 10px;
      cursor: pointer;
      position: relative;
      @include response(1200px) {
        .main-slider & {
          margin-right: 10px;
        }
      }
      &.slick-active {
        &::before {
          background: $red;
        }
      }
      &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: #b5bebe;
        border-radius: 50%;
        left: 4px;
        top: 4px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }