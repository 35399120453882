@keyframes circle {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    stroke-dasharray: 100 100;
    opacity: 0;
   }
}