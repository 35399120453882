.tab{
  display: none;
  &.active{
    display: block;
  }
}
.tabs-list {
  position: relative;
  display: flex;
  margin-bottom: 30px;
  &__item {
    margin-left: 40px;
    color: $black;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 1px;
    @include response(767px) {
      margin-left: 0;
      margin-right: 20px;
    }
    @include desktop-hover() {
      color: $red;
    }
    &.active {
      color: $red;
      border-color: $red;
    }
  }
}