body {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  line-height: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.wrapper {
  position: relative;
  max-width: 100%;
  flex: 1 1 auto;
  overflow: hidden;
  &--home {
    @include response(1200px) {
      padding-top: 61px;
    }
  }
}
.container {
  position: relative;
  max-width: 1152px;
  width: 100%;
  margin: 0 auto;
  padding-top: 110px;
  @include response(1440px) {
    padding-top: 100px;
  }
  @include response(1439px) {
    padding-top: 90px;
  }
  @include response(1200px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @include response(767px) {
    padding-top: 75px;
  }
  &--middle {
    padding-top: 0;
  }
}
.title {
  text-align: center;
  font-size: 56px;
  font-weight: 700;
  color: $black;
  margin-bottom: 90px;
  @include response(1440px) {
    margin-bottom: 20px;
    font-size: 36px;
  }
  @include response(767px) {
    font-size: 26px;
  }
  &--contacts {
    margin-bottom: 40px;
    @include response(1440px) {
      margin-bottom: 20px;
    }
  }
}
.title-page {
  position: relative;
  margin-bottom: 60px;
  padding: 0 37px 46px;
  @include response(1440px) {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  @include response(1200px) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
  }
  @include response(767px) {
    margin-bottom: 20px;
  }
  &--our-strategy {
    margin-bottom: 0;
  }
  &--research {
    margin-bottom: 38px;
  }
  &--production {
    margin-bottom: 30px;
  }
  &--partnership {
    margin-bottom: 40px;
    @include response(767px) {
      margin-bottom: 20px;
    }
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: -48px;
    width: 290px;
    height: 203px;
    background: url('../images/decor.png') center/100% 100% no-repeat;
    @include response(1200px) {
      display: none;
    }
  }
  &--pharmacovigilance {
    padding-bottom: 30px;
    &::before {
      top: 3px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 37px;
    right: 37px;
    height: 1px;
    background: #ddd;
    @include response(1200px) {
      left: 0;
      right: 0;
    }
  }
  &__text-secondary {
    position: relative;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    color: $black;
    @include response(767px) {
      font-size: 14px;
    }
  }
  &__text-descriptions {
    position: relative;
    text-align: center;
    color: $black;
    font-size: 18px;
    @include response(767px) {
      font-size: 14px;
    }
  }
  &__text {
    color: $black;
    font-size: 38px;
    text-transform: uppercase;
    letter-spacing: 8px;
    position: relative;
    @include response(1200px) {
      font-size: 30px;
    }
    @include response(767px) {
      font-size: 19px;
      text-align: center;
    }
    &--pharmacovigilance {
      text-align: center;
      margin-bottom: 20px;
    }
    &--center {
      text-align: center;
    }
  }
  &__decor {
    color: #b5bebe;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 300;
    position: relative;
    padding-left: 30px;
    transform: rotate(-90deg);
    position: absolute;
    left: -5px;
    top: 255px;
    transform-origin: 0 0;
    @include response(1200px) {
      display: none;
    }
    &--people-one {
      top: 155px;
    }
    &--pharmacovigilance {
      top: 350px;
    }
    &--products {
      top: 330px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 7px;
      height: 7px;
      background: #b5bebe;
      border-radius: 50%;
    }
  }
}
* {
  box-sizing: border-box;
  &::after,
  &::before {
    box-sizing: border-box;
  }
}
 a,
 svg {
   transition: .3s ease-in-out;
 }