.research {
  position: relative;
  &__top {
    display: flex;
    margin-bottom: 100px;
    padding-left: 47px;
    padding-right: 47px;
    align-items: center;
    @include response(1200px) {
      padding-right: 0;
      padding-left: 0;
      flex-wrap: wrap;
      margin-bottom: 50px;
      justify-content: center;
    }
  }
  &__top-img {
    display: flex;
    margin-right: 110px;
    position: relative;
    flex: 0 0 auto;
    @include response(1200px) {
      margin-right: 0;
      margin-bottom: 10px;
      flex: 0 0 50%;
      display: block;
    }
    @include response(767px) {
      flex: 0 0 100%;
    }
    img {
      position: relative;
      z-index: 1;
    }
    &::before {
      content: '';
      position: absolute;
      width: 231px;
      height: 308px;
      background: #f1f5f5;
      bottom: -35px;
      right: -30px;
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__top-info {
    flex: 1 1 auto;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      right: -47px;
      top: -120px;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__top-title {
    text-transform: uppercase;
    margin-bottom: 35px;
    line-height: 32px;
    font-size: 28px;
    font-weight: 500;
    position: relative;
    @include response(1200px) {
      margin-bottom: 15px;
      font-size: 22px;
      line-height: 28px;
    }
  }
  &__list {
    padding-top: 60px;
    padding-bottom: 110px;
    position: relative;
    @include response(1200px) {
      padding-bottom: 40px;
    }
    @include response(767px) {
      padding-bottom: 20px;
      padding-top: 30px;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 136px;
    @include response(1200px) {
      margin-bottom: 40px;
    }
    @include response(767px) {
      flex-wrap: wrap;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(even) {
      .research__item-info {
        order: 2;
        @include response(1200px) {
          padding-left: 20px;
        }
        @include response(767px) {
          padding-left: 0;
        }
      }
      .research__item-img {
        &::before {
          right: auto;
          left: -16px;
          top: -16px;
          @include response(1200px) {
            display: none;
          }
        }
      }
    }
  }
  &__item-info {
    flex: 0 0 50%;
    padding-left: 47px;
    padding-right: 47px;
    position: relative;
    @include response(1200px) {
      padding-left: 0;
      padding-right: 20px;
    }
    @include response(767px) {
      order: 2;
      flex: 0 0 100%;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -114px;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__item-title {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 500;
    color: #000;
    position: relative;
    margin-bottom: 25px;
    @include response(767px) {
      margin-bottom: 15px;
      font-size: 22px;
    }
  }
  &__item-img {
    position: relative;
    @include response(767px) {
      margin-bottom: 15px;
    }
    img {
      position: relative;
      z-index: 1;
    }
    &::before {
      content: '';
      position: absolute;
      width: 241px;
      height: 308px;
      background: #f1f5f5;
      right: -20px;
      bottom: -42px;
      @include response(1200px) {
        display: none;
      }
    }
  }
}