.partnership {
  position: relative;
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    @include response(1200px) {
      margin-bottom: 10px;
    }
  }
  &__item {
    flex: 0 0 25%;
    max-width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding: 10px;
    @include response(1200px) {
      margin-bottom: 10px;
    }
    @include response(767px) {
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 0;
    }
  }
  &__text {
    max-width: 810px;
    width: 100%;
    margin: 0 auto 60px;
    @include response(1200px) {
      margin-bottom: 30px;
    }
  }
  &__video {
    height: 564px;
    background: #000;
    position: relative;
    margin-bottom: 30px;
    @include response(1200px) {
      height: 400px;
    }
    @include response(767px) {
      height: 300px;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 0;
    }
    &--people-one {
      margin-bottom: 126px;
      @include response(1440px) {
        margin-bottom: 60px;
      }
      @include response(1200px) {
        margin-bottom: 20px;
      }
    }
  }
}