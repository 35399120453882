.people {
  position: relative;
  padding-bottom: 130px;
  @include response(1440px) {
    padding-bottom: 60px;
  }
  &__top {
    display: flex;
    margin-bottom: 54px;
    @include response(767px) {
      margin-bottom: 30px;
      flex-wrap: wrap;
    }
  }
  &__top-title {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 20px 40px 40px;
    color: #393c3f;
    font-size: 38px;
    text-transform: uppercase;
    letter-spacing: 8px;
    position: relative;
    @include response(1200px) {
      padding-left: 0;
      padding-top: 0;
    }
    @include response(767px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 10px;
      padding: 0 0 15px;
      font-size: 22px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 290px;
      height: 203px;
      background: url('../images/decor.png') center/100% 100% no-repeat;
      z-index: -1;
      @include response(767px) {
        display: none;
      }
    }
    &::after {
      content: '';
      position: absolute;
      left: 40px;
      right: 40px;
      bottom: 0;
      height: 1px;
      background: #ddd;
      @include response(1200px) {
        left: 0;
      }
      @include response(767px) {
        right: 0;
      }
    }
  }
  &__top-text {
    padding-top: 20px;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 48px;
    padding-right: 60px;
    @include response(1200px) {
      padding: 0;
    }
    @include response(767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  &__nav {
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 30px;
    @include response(1200px) {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 20px;
      overflow-x: auto;
    }
    @include response(767px) {
      padding-left: 15px;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  &__nav-link {
    margin-right: 50px;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    color: $black;
    border-bottom: 2px solid transparent;
    padding-bottom: 3px;
    @include response(1200px) {
      margin-right: 30px;
      flex: 0 0 auto;
    }
    @include desktop-hover() {
      color: $red;
      border-color: $red;
    }
    &.active {
      color: $red;
      border-color: $red;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 1080px;
    width: 100%;
    margin: 0 auto;
    @include response(767px) {
      width: auto;
      max-width: none;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  &__item {
    position: relative;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    overflow: hidden;
    @include response(767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .people-one__slider & {
      flex: 0 0 100%;
      max-width: 100%;
    }
    @include desktop-hover() {
      .people__item-hover {
        transform: translateY(0);
      }
    }
    img {
      filter: grayscale(100%);
    }
  }
  &__item-hover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,6,142,.8);
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    transition: transform .3s ease-in-out;
    transform: translateY(100%);
    @include response(1200px) {
      transform: translateY(0);
      padding: 10px;
      border: 1px solid #fff;
      justify-content: flex-end;
    }
  }
  &__item-name {
    text-transform: uppercase;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 700;
    @include response(1200px) {
      font-size: 16px;
    }
  }
  &__item-position {
    margin-bottom: 16px;
    font-size: 17px;
    font-weight: 700;
    @include response(1200px) {
      font-size: 14px;
    }
  }
  &__item-descriptions {
    font-size: 16px;
    line-height: 26px;
    height: 156px;
    overflow: hidden;
    margin-bottom: 16px;
    @include response(1200px) {
      font-size: 12px;
      line-height: 14px;
      height: 70px;
    }
    @include response(767px) {
      display: none;
    }
  }
  &__item-link {
    padding: 10px;
    min-width: 146px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-decoration: none;
    color: #fff;
    background: rgba(255,255,255,.2);
    align-self: flex-start;
    @include response(767px) {
      min-width: 100%;

    }
    @include desktop-hover() {
      background: rgba(255,255,255,.4);
    }
  }
}