.distribution {
  position: relative;
  padding-bottom: 160px;
  @include response(1440px) {
    padding-bottom: 70px;
  }
  @include response(767px) {
    padding-bottom: 30px;
  }
  &__img {
    margin-bottom: 60px;
    padding-left: 38px;
    padding-right: 38px;
    @include response(1200px) {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 20px;
    }
  }
  &__text {
    max-width: 810px;
    width: 100%;
    margin: 0 auto;
  }
}