.growth {
  position: relative;
  &__top {
    display: flex;
    padding-left: 45px;
    padding-right: 45px;
    margin-bottom: 100px;
    @include response(1200px) {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 40px;
    }
    @include response(767px) {
      flex-wrap: wrap;
    }
  }
  &__top-text {
    flex: 1 1 auto;
    padding-top: 20px;
    @include response(1200px) {
      padding-top: 0;
    }
    @include response(767px) {
      order: 2;
    }
  }
  &__top-img {
    flex: 0 0 auto;
    margin-left: 90px;
    position: relative;
    @include response(1200px) {
      margin-left: 20px;
      flex: 0 0 30%;
    }
    @include response(767px) {
      flex: 0 0 100%;
      margin-left: 0;
      margin-bottom: 15px;
      order: 1;
      img {
        width: 100%;
      }
    }
    &::before {
      content: '';
      position: absolute;
      bottom: -40px;
      right: -45px;
      width: 112px;
      height: 126px;
      background: #f8f9f9;
      z-index: -1;
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__text-inner {
    max-height: 503px;
  }
  &__middle {
    position: relative;
    margin-bottom: 120px;
    padding-left: 45px;
    padding-right: 45px;
    @include response(1440px) {
      margin-bottom: 60px;
    }
    @include response(1200px) {
      padding-left: 0;
      padding-right: 0;
    }
    &--pharmacovigilance {
      margin-bottom: 75px;
      padding-top: 20px;
      @include response(767px) {
        margin-bottom: 30px;
      }
    }
  }
  &__middle-title {
    text-align: center;
    text-transform: uppercase;
    color: $black;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 34px;
    position: relative;
    @include response(1200px) {
      margin-bottom: 15px;
    }
    @include response(767px) {
      font-size: 22px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -209px;
      top: -120px;
      width: 209px;
      height: 202px;
      background: url('../images/decor4.png') center/100% 100% no-repeat;
      z-index: -1;
      @include response(1200px) {
        display: none;
      }
    }
  }
  &__middle-table {
    display: flex;
    @include response(767px) {
      flex-wrap: wrap;
    }
  }
  &__middle-cell {
    width: 100%;
    & + .growth__middle-cell {
      margin-left: 70px;
      @include response(1200px) {
        margin-left: 20px;
      }
      @include response(767px) {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }
  &__bottom {
    padding-left: 45px;
    padding-right: 45px;
    display: flex;
    align-items: center;
    margin-bottom: 80px;
    @include response(1200px) {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 40px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__bottom-img {
    flex: 0 0 auto;
    margin-right: 100px;
    @include response(1200px) {
      flex: 0 0 50%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    @include response(767px) {
      flex: 0 0 100%;
    }
  }
  &__bottom-info {
    position: relative;
    @include response(1200px) {
      flex: 1 1 auto;
    }
  }
  &__bottom-title {
    font-size: 28px;
    font-weight: 700;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 20px;
    @include response(767px) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
}