.btn {
  position: relative;
  background: #fff;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 900;
  border: 2px solid $black;
  text-align: center;
  color: $black;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  text-decoration: none;
  padding: 15px 54px;
  border-radius: 4px;
  min-width: 200px;
  transform: perspective(1px) translateZ(0);
  @include response(767px) {
    padding: 12px 42px;
    min-width: auto;
  }
  @include desktop-hover() {
    color: #fff;
    &::before {
      transform: scaleY(1);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $black;
    z-index: -1;
    transform: scaleY(0);
    transform-origin: 50% 0;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }
}