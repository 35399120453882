.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: #fff;
  padding: 100px 15px 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-100vw,0);
  transition: opacity .3s ease-in-out;
  opacity: 0;
  @include response(1440px) {
    padding-bottom: 15px;
    padding-top: 90px;
  }
  @include response(1200px) {
    background: transparent;
    transition: 0s;
  }
  &.active {
    transform: translate(0,0);
    opacity: 1;
  }
  &__overlay {
    display: none;
    @include response(1200px) {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255,255,255,.4);
      transition: .3s ease-in-out;
      opacity: 0;
      .active & {
        opacity: 1;
      }
    }
  }
  &__holder {
    @include response(1200px) {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: #fff;
      width: 305px;
      box-shadow: 3px 0 20px 0 rgba(33, 31, 32, 0.1);
      overflow-y: auto;
      transition: transform .3s ease-in-out;
      transform: translateX(-100%);
      &.active {
        transform: translateX(0);
      }
    }
  }
  &__head {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 37px 15px 20px;
    @include response(1440px) {
      padding: 15px;
    }
    @include response(1200px) {
      padding: 0;
      position: relative;
    }
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    max-width: 1150px;
    width: 100%;
    bottom: 45px;
    left: 50%;
    margin-left: -575px;
  }
  &__copy {
    color: #8e9399;
    font-size: 14px;
    font-weight: 300;
    @include response(1200px) {
      text-align: center;
      font-size: 11px;
    }
    .body-home .footer & {
      display: none;
    }
  }
  &__box {
    position: relative;
  }
  &__title {
    color: $black;
    font-weight: 700;
    font-size: 56px;
    margin-bottom: 100px;
    text-align: center;
    transition: .3s ease-in-out;
    transform: translate(-40px,0);
    opacity: 0;
    transition-delay: .3s;
    @include response(1440px) {
      font-size: 44px;
      margin-bottom: 40px;
    }
    @include response(1200px) {
      transform: translate(0,0);
      opacity: 1;
      font-size: 24px;
      margin-bottom: 20px;
      margin-top: 15px;
    }
    .active.menu & {
      opacity: 1;
      transform: translate(0,0);
    }
  }
  &__head-left {
    display: flex;
    align-items: center;
  }
  &__main-close {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #8e9399;
    font-size: 16px;
    transition: .3s ease-in-out;
    @include response(1200px) {
      padding: 22px;
    }
    @include desktop-hover() {
      color: $red;
      svg {
        fill: $red;
      }
    }
    svg {
      margin-right: 11px;
      margin-bottom: 2px;
      fill: #393c3f;
    }
  }
  &__box-main {
    position: relative;
    max-width: 1152px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    @include response(1200px) {
      display: block;
    }
  }
  &__nav {
    flex: 0 0 50%;
    max-width: 50%;
    list-style: none;
    padding: 0;
    margin: 0;
    column-count: 2;
    column-gap: 0;
    @include response(1200px) {
      column-count: auto;
      max-width: 100%;
    }
  }
  &__nav-link {
    display: block;
    font-weight: 700;
    font-size: 28px;
    color: $black;
    padding-right: 10px;
    text-decoration: none;
    @include response(1200px) {
      font-size: 18px;
      padding: 17px 24px;
    }
    @include desktop-hover() {
      color: $red;
    }
    .menu__nav-parent--drop & {
      padding-left: 20px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: $red;
        width: 6px;
      }
    }
  }
  &__nav-parent {
    margin-bottom: 37px;
    @include response(1440px) {
      margin-bottom: 20px;
    }
    @include response(1200px) {
      margin-bottom: 0;
      border-top: 1px solid #f6f6f6;
    }
    // &--drop {
      
    // }
  }
  &__drop {
    list-style: none;
    margin: 0;
    padding: 16px 0 0 22px;
    @include response(1200px) {
      padding: 16px 24px;
      border-top: 1px solid #f6f6f6;
    }
  }
  &__drop-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__drop-link {
    text-decoration: none;
    color: $black;
    font-size: 17px;
    font-weight: 500;
    border-bottom: 1px solid transparent;
    @include response(1200px) {
      display: block;
      font-size: 14px;
      padding-left: 10px;
    }
    @include desktop-hover() {
      color: $red;
      border-color: $red;
    }
  }
  &__contacts-box {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 60px;
    @include response(1200px) {
      padding: 17px 24px 0;
      border-top: 1px solid #f6f6f6;
      max-width: 100%;
    }
  }
  &__contacts-cell {
    margin-bottom: 75px;
    &:last-child {
      margin-bottom: 0;
    }
    @include response(1440px) {
      margin-bottom: 30px;
    }
  }
  &__contacts-title {
    margin-bottom: 20px;
    color: $black;
    font-size: 22px;
    font-weight: 500;
  }
}
.worldwide {
  display: flex;
  flex-wrap: wrap;
  max-width: 576px;
  transition: .3s ease-in-out;
  transform: translate(-40px,0);
  opacity: 0;
  transition-delay: .4s;
  @include response(1200px) {
    opacity: 1;
    transform: translate(0,0);
  }
  &--main {
    max-width: 100%;
    opacity: 1;
    transform: none;
    @include response(1200px) {
      margin-left: -24px;
      margin-right: -24px;
      max-width: none;
      border-top: 1px solid #f3f3f4;
    }
  }
  .active.menu & {
    opacity: 1;
    transform: translate(0,0);
  }
  &__item {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    text-decoration: none;
    color: #555d65;
    .menu--worldwide & {
      @include response(1200px) {
        &:first-child {
          border-top: 1px solid #f3f3f4;
        }
        flex: 0 0 100%;
        max-width: 100%;
        border-bottom: 1px solid #f3f3f4;
        padding: 12px 24px;
        margin-bottom: 0;
      }
    }
    .worldwide--main & {
      @include response(1200px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0;
        padding-top: 10px;
        border-bottom: 1px solid #f3f3f4;
        padding-bottom: 10px;
        &:nth-child(odd) {
          border-right: 1px solid #f3f3f4;
        }
      }
    }
    @include response(767px) {
      margin-bottom: 0;
      padding-top: 15px;
      border-bottom: 1px solid #f3f3f4;
      padding-bottom: 10px;
      &:nth-child(odd) {
        border-right: 1px solid #f3f3f4;
      }
    }
    .countries & {
      flex: 0 0 20%;
      max-width: 20%;
      margin-bottom: 38px;
      @include response(767px) {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 0;
        padding-top: 10px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      } 
    }
    @include desktop-hover() {
      .worldwide__text {
        color: $red;
        border-color: $red;
      }
    }
  }
  &__icon {
    margin-right: 16px;
    flex: 0 0 42px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    .worldwide--main & {
      flex: 0 0 28px;
      width: 28px;
      height: 28px;
      @include response(1200px) {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
    .countries & {
      flex: 0 0 36px;
      width: 36px;
      height: 36px;
      margin-right: 12px;
      @include response(767px) {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
  }
  &__text {
    font-size: 22px;
    font-weight: 500;
    border-bottom: 1px solid transparent;
    transition: .3s ease-in-out;
    color: #555d65;
    .worldwide--main & {
      @include response(1200px) {
        flex: 0 0 100%;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    @include response(767px) {
      flex: 0 0 100%;
      text-align: center;
      padding-left: 5px;
      padding-right: 5px;
      .menu--worldwide & {
        flex: 1 1 auto;
        text-align: left;
      }
    }
    .worldwide--main & {
      font-size: 17px;
    }
    .countries & {
      font-size: 17px;
    }
  }
}