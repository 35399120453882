.error-page {
  min-height: calc(100vh - 108px);
  padding-top: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 15px;
  @include response(1440px) {
    min-height: calc(100vh - 70px);
    padding-top: 70px;
  }
  @include response(1200px) {
    min-height: calc(100vh - 157px);
  }
  @media screen and (max-width:767px) and (orientation:landscape) {
    align-items: flex-start;
  }
  &__box {
    position: relative;
  }
  &__title {
    font-size: 340px;
    font-weight: 500;
    color: $red;
    @include response(1440px) {
      font-size: 200px;
    }
    @include response(767px) {
      font-size: 100px;
    }
  }
  &__descriptions {
    font-size: 56px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: $black;
    @include response(767px) {
      font-size: 30px;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 26px;
    max-width: 290px;
    width: 100%;
    margin: 0 auto 20px;
  }
  &__decor {
    position: absolute;
    top: -50px;
    fill: #f7f8f8;
    @include response(767px) {
      display: none;
    }
    &--left {
      right: 100%;
    }
    &--right {
      left: 100%;
    }
  }
}