.people-one {
  position: relative;
  padding-top: 55px;
  @include response(1200px) {
    padding-top: 0;
  }
  &__back-box {
    margin-bottom: 30px;
    margin-left: -257px;
    margin-right: -257px;
    @include response(1200px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__back {
    display: inline-flex;
    align-items: center;
    color: $black;
    font-size: 16px;
    text-decoration: none;
    position: relative;
    @include desktop-hover() {
      svg {
        transform: translateX(-5px);
      }
    }
    svg {
      margin-right: 10px;
    }
  }
  &__top {
    display: flex;
    margin-bottom: 125px;
    @include response(1440px) {
      margin-bottom: 60px;
    }
    @include response(1200px) {
      margin-bottom: 40px;
    }
    @include response(767px) {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
  }
  &__top-info {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 40px 50px 0 45px;
    position: relative;
    @include response(1200px) {
      padding: 0 15px 0 0;
    }
    @include response(767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
      order: 2;
    }
  }
  &__top-img {
    flex: 0 0 50%;
    max-width: 50%;
    @include response(767px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
  &__text-inner {
    max-height: 504px;
    @include response(1200px) {
      max-height: 370px;
    }
    @include response(767px) {
      max-height: 250px;
    }
  }
  &__top-title {
    margin-bottom: 10px;
    font-size: 42px;
    font-weight: 700;
    color: $black;
    text-transform: uppercase;
    @include response(1200px) {
      font-size: 24px;
    }
  }
  &__top-positions {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    color: $red;
    @include response(1200px) {
      margin-bottom: 10px;
    }
  }
  &__slider {
    padding-left: 37px;
    padding-right: 37px;
    margin-bottom: 170px;
    @include response(1440px) {
      margin-bottom: 90px;
    }
    @include response(767px) {
      margin-left: -15px;
      margin-right: -15px;
    }
    @include desktop-hover() {
    .slick-active .circle {
        animation: none;
      }
    } 
    .slick-arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 37px;
      cursor: pointer;
      z-index: 1;
      @include desktop-hover() {
        svg {
          fill: $red;
        }
      }
    }
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
    .slick-dots {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: -60px;
      left: 0;
      right: 0;
      li {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}