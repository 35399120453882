.breadcrumb {
  padding: 0;
  margin: 0 0 17px;
  display: flex;
  justify-content: center;
  list-style: none;
  @include response(1440px) {
    margin-bottom: 10px;
  }
  @include response(1200px) {
    display: none;
  }
  &__item {
    position: relative;
    margin-right: 24px;
    font-size: 16px;
    @include response(1440px) {
      font-size: 14px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    display: block;
    position: relative;
    color: #555d65;
    text-decoration: none;
    @include desktop-hover() {
      color: $red;
    }
    &::before {
      content: '';
      position: absolute;
      right: -12px;
      top: -1px;
      width: 1px;
      height: 17px;
      transform: rotate(15deg);
      background: #c1c3c7;
    }
  }
  &__current {
    color: #8e9399;
  }
}