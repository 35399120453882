.news-box {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 50px;
  @include response(1200px) {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 20px;
  }
}
.news {
  display: flex;
  margin-left: -10px;
  @include response(767px) {
    margin-left: 0;
    flex-wrap: wrap;
  }
  &__one {
    margin-left: 10px;
    flex: 0 0 calc(33.333% - 10px);
    @include response(767px) {
      margin-left: 0;
      margin-bottom: 20px;
      flex: 0 0 100%;
    }
  }
  &__one-img {
    display: block;
    margin-bottom: 20px;
    position: relative;
    @include response(767px) {
      margin-bottom: 10px;
    }
    @include desktop-hover() {
      &::before {
        opacity: 1;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0,0,0,.4);
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }
  }
  &__one-date {
    color: #999;
    font-size: 14px;
    margin-bottom: 8px;
  }
  &__one-title {
    display: block;
    text-decoration: none;
    color: #333;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    padding-right: 30px;
    @include desktop-hover() {
      text-decoration: underline;
      color: $red;
    }
  }
}