.countries {
  position: relative;
  padding-bottom: 50px;
  @include response(1200px) {
    padding-bottom: 0;
  }
  @include response(767px) {
    margin-bottom: 15px;
  }
  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: -40px;
    width: 290px;
    height: 203px;
    background: url('../images/decor.png') center/100% 100% no-repeat;
    @include response(1200px) {
      display: none;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 80px;
    position: relative;
    @include response(1200px) {
      padding-left: 0;
    }
    @include response(767px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}